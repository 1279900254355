@import '../../styles/carousel';
@import '../../styles/spacing';
@import '../../styles/responsive';
@import '../../styles/typography';

$image-width: 330px;

.text_and_image_block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: $spacing-3;

  @include only-on(lg) {
    padding: $spacing-3 $spacing-4;
  }

  @include only-on(xl) {
    flex-direction: row;
    justify-content: center;
    padding: $spacing-3 0;
  }
}

.text_and_image_block--light_blue {
  background: $background-light-blue;
}

.text_and_image_block--tan {
  background: $vroom-tan;
}

.text_and_image_block--extra-top-spacing,
.text_and_image_block--extra-top-and-bottom-spacing {
  padding-top: $spacing-4;

  @include only-on(xl) {
    padding-top: $spacing-5;
  }
}

.text_and_image_block--extra-bottom-spacing,
.text_and_image_block--extra-top-and-bottom-spacing {
  padding-bottom: $spacing-4;

  @include only-on(xl) {
    padding-bottom: $spacing-5;
  }
}

.text_and_image_block--wide_text {
  .text_and_image_block__description {
    ul {
      max-width: 480px;
    }
  }

  @include only-on(xl) {
    .text_and_image_block__image {
      flex: 1 1 auto;
      max-width: $image-width;
    }

    .text_and_image_block__text {
      flex: 2 1 auto;
      max-width: $block-text-width-max;
    }
  }
}

.text_and_image_block--wide_image {
  @include only-on(lgUp) {
    .text_and_image_block__image {
      img {
        width: auto;
        height: auto;
      }
    }
  }

  @include only-on(xl) {
    .text_and_image_block__image {
      flex: 2 1 auto;
      max-width: $block-text-width-max;
    }

    .text_and_image_block__text {
      flex: 1 1 auto;
    }
  }
}

.text_and_image_block--stacked {
  display: flex;
  flex-direction: column;

  .text_and_image_block__image {
    margin-right: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .text_and_image_block__text {
    text-align: center;

    @include only-on(xl) {
      max-width: $block-text-width-max;
      padding-top: $spacing-3;
    }
  }
}

.text_and_image_block__image {
  img {
    width: 305px;
    height: auto;

    @include only-on(lgUp) {
      width: $image-width;
    }
  }
}

.text_and_image_block--image_center {
  .text_and_image_block__image {
    @include only-on(xl) {
      text-align: center;
    }
  }
}

.text_and_image_block--image_left {
  .text_and_image_block__image {
    @include only-on(xl) {
      margin-right: $spacing-4;
      text-align: right;
    }
  }

  .text_and_image_block__text {
    @include only-on(xl) {
      margin-left: $spacing-4;
    }
  }
}

.text_and_image_block--image_right {
  @include only-on(xl) {
    flex-direction: row-reverse;
  }

  .text_and_image_block__image {
    @include only-on(xl) {
      margin-left: $spacing-4;
      text-align: left;
    }
  }

  .text_and_image_block__text {
    @include only-on(xl) {
      margin-right: $spacing-4;
    }
  }
}

.text_and_image_block__text {
  padding-top: $spacing-3;

  @include only-on(xl) {
    max-width: 320px;
    padding: 0;
    text-align: left;
  }
}

.text_and_image_block__title {
  @extend .headline_small;
  color: $vroom-teal;
}

.text_and_image_block__description {
  @extend .text_small;
  margin-top: $spacing-1;

  @include only-on(lgUp) {
    max-width: 600px;
  }

  h2 {
    @extend .headline_small;
    margin-bottom: $spacing-2;
  }

  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: $spacing-2;
  }

  ul {
    list-style-type: none;
    margin-block-start: $spacing-2;
    margin-block-end: 0;
    padding-inline-start: 18px;
  }

  li {
    padding-bottom: $spacing-2;
  }

  li::before {
    color: $vroom-teal;
    content: '\2022';
    display: inline-block;
    font-size: 32px;
    margin-left: -18px;
    vertical-align: text-bottom;
    width: 18px;
  }

  a {
    @extend .text_small;
  }
}
